import { useEffect, useState } from "react";
import moment from "moment";
import slugify from "../utils/slugify";

export const useStoreLocation = () => {
  const [storeLocation, setStoreLocation] = useState("uk");

  const productPath = (name) => {
    return `/products/${storeLocation}/${slugify(name)}`;
  };

  // Get store location. Set to UK by default. Logic in /components/CountrySelectModal
  useEffect(() => {
    const location = typeof window !== "undefined" && localStorage.getItem("storeLocation");

    if (location === "us") {
      setStoreLocation(location);
    }
  }, []);

  // Return currency symbol based on store location. Used in components/CartItem with rawPrice as formatProductPrice can not be calculated
  const storeCurrencySymbol = () => {
    switch (storeLocation) {
      case "uk":
        return "£";
      case "us":
        return "$";
      default:
        return;
    }
  };

  // Return currency to find correct product prices in product components & build correct checkout URL
  const storeCurrency = () => {
    switch (storeLocation) {
      case "uk":
        return "GBP";
      case "us":
        return "USD";
      default:
        return;
    }
  };

  // Get product price based on storeCurrency & return formatted with currency symbol
  const formatProductPrice = (prices) => {
    const getPrice = prices?.find((item) => item.currencyCode === storeCurrency())?.price || 0;
    const price = (getPrice / 100).toFixed(2) || 0;

    const currency = storeCurrency();

    // If no price, return false (used for checking if salePrice exists)
    if (!getPrice) {
      return false;
    }

    if (currency === "GBP") {
      return `£${price}`;
    } else if (currency === "USD") {
      return `$${price}`;
    }
  };

  // check if the sale is active based on the defined start and end dates of the sale
  const checkSalePrice = (salesPrices) => {
    if (!salesPrices) return false;

    const { end_date, start_date } = salesPrices;

    if (!end_date || !start_date) {
      return false;
    }

    const start = moment(start_date);
    const end = moment(end_date);
    const now = moment();

    const saleIsActive = now.isAfter(start) && now.isBefore(end);

    return saleIsActive ? formatProductPrice(salesPrices.prices) : false;
  };

  // Returns price without formatting. Used for calculations in /components/CartItem
  const rawPrice = (prices) => {
    const getPrice = prices?.find((item) => item.currencyCode === storeCurrency())?.price || 0;
    const price = (getPrice / 100).toFixed(2) || 0;

    if (!price) {
      return false;
    }

    return price;
  };

  return {
    storeLocation,
    storeCurrency,
    formatProductPrice,
    checkSalePrice,
    storeCurrencySymbol,
    rawPrice,
    productPath,
  };
};
