import React from "react";
import { Flex } from "../../elements/Flex";
import { Heading } from "../../elements/Heading";
import { Text } from "../../elements/Text";
import { Span } from "../../elements/Span";
import { Link } from "gatsby";
import { Box } from "../../elements/Box";
import { ExternalLink } from "../../elements/ExternalLink";
import { useStoreLocation } from "../../hooks/useStoreLocation";

const UKShipping = () => {
  return (
    <>
      <Text fontWeight="600">UK:</Text>
      <Text>Tracked</Text>
      <Text mb={3}>Starting from: £2.99</Text>

      <Text fontWeight="600">Europe:</Text>
      <Text>Untracked: Starting from: £3.75</Text>
      <Text>Tracked: Starting from: £8.00</Text>
      <Text mb={3}>Express: Starting from: £12.00</Text>

      <Text fontWeight="600">United States:</Text>
      <Text>Untracked: Starting from: £5.00</Text>
      <Text>Tracked: Starting from: £10.50</Text>
      <Text mb={3}>Express: Starting from: £16.00</Text>

      <Text fontWeight="600">Canada:</Text>
      <Text>Untracked: Starting from: £3.00</Text>
      <Text>Tracked: Starting from: £8.30</Text>
      <Text mb={3}>Express: Starting from: £19.00</Text>

      <Text fontWeight="600">South America:</Text>
      <Text>Untracked: Starting from: £3.75</Text>
      <Text>Tracked: Starting from: £6.85</Text>
      <Text mb={3}>Express: Starting from: £39.00</Text>

      <Text fontWeight={600}>Asia:</Text>
      <Text>Untracked: Starting from: £3.00</Text>
      <Text>Tracked: Starting from: £7.30</Text>
      <Text mb={3}>Express: Starting from: £22.00</Text>

      <Text fontWeight={600}>Oceania:</Text>
      <Text>Untracked: Starting from: £3.75</Text>
      <Text>Tracked: Starting from: £6.85</Text>
      <Text mb={3}>Express: Starting from: £29.00</Text>

      <Text fontWeight={600}>Africa:</Text>
      <Text>Untracked: Starting from: £3.50</Text>
      <Text>Tracked: Starting from: £9.30</Text>
      <Text mb={3}>Express: Starting from: £29.00</Text>

      <Heading fontSize={24} mb={3}>
        BREXIT (THE UK LEAVING THE EUROPEAN UNION) – CONSEQUENCES FOR EUROPEAN CUSTOMERS
      </Heading>

      <Text mb={3}>
        As our goods are shipped from the UK, and the UK has now left the European Union, there may now be additional
        charges to pay on delivery of your goods. Please read and note the below.
      </Text>

      <Text fontWeight={600} mb={3}>
        VAT
      </Text>

      <Text mb={3}>
        VAT will now be payable in EU countries where the customer resides – rates vary from country to country but
        average 20%. *VAT will be payable on delivery of the parcel in the local country. *The customer will have an
        invoice attached to the shipment for payment.
      </Text>

      <Text mb={3}>
        The Low Value Consignment relief provisions apply until June 2021 –* until then you can sell purchases goods
        under the value of €22 without VAT being applicable*.
      </Text>

      <Text mb={3}>
        To find the VAT rate in your local country please use this link –{" "}
        <a
          href="https://europa.eu/youreurope/business/taxation/vat/vat-rules-rates/index_en.htm"
          target="_blank"
          rel="noopener norefferrer"
        >
          https://europa.eu/youreurope/business/taxation/vat/vat-rules-rates/index_en.htm
        </a>{" "}
      </Text>

      <Text mb={3} fontWeight={600}>
        Duty and Tariffs
      </Text>

      <Text mb={3}>
        When ordering internationally you may have to pay import duty depending on what you’ve ordered and its declared
        value.
      </Text>

      <Text mb={3}>
        Import duty is a tax that may be imposed by your country’s government on goods from other countries, so
        unfortunately this is not something we can control.
      </Text>

      <Text mb={3}>
        When sending parcels to the EU from the UK, Duty is only payable for any orders *over €150 in value*. Duty may
        apply to restricted goods, including alcohol, tobacco, etc*.*
      </Text>

      <Text mb={3}>
        Duty will be based on the type and material of each good, and the location the customer resides in. The customer
        will have an invoice attached to the shipment for payment.
      </Text>

      <Text mb={3}>
        Please use this website to look up potential payable duty charges{" "}
        <a
          href="          https://www.check-duties-customs-exporting-goods.service.gov.uk/selectdest?_ga=2.24855993.2043019202.1611132980-1190965269.1588795992"
          target="_blank"
          rel="noopener norefferrer"
        >
          https://www.check-duties-customs-exporting-goods.service.gov.uk/selectdest?_ga=2.24855993.2043019202.1611132980-1190965269.1588795992
        </a>
         
      </Text>

      <Text mb={3}>
        It is not guaranteed that there will be a duty charge, it is dependant on each shipment and each customs
        official around the world.
      </Text>
    </>
  );
};

const USShipping = () => {
  return (
    <>
      <Text fontWeight="600">United States:</Text>
      <Text mb={3}>Standard : Starting from $5</Text>

      <Text fontWeight="600">Canada:</Text>
      <Text>Standard : Starting from $15</Text>
      <Text mb={3}>Expedited : Starting from $23</Text>

      <Text fontWeight="600">South America:</Text>
      <Text>Standard : Starting from $15</Text>
      <Text mb={3}>Expedited : Starting from $45</Text>

      <Text fontWeight="600">Europe:</Text>
      <Text>Standard : Starting from $20</Text>
      <Text mb={3}>Expedited : Starting from $45</Text>

      <Text fontWeight="600">Asia:</Text>
      <Text>Standard : Starting from $13</Text>
      <Text mb={3}>Expedited : Starting from $50</Text>

      <Text fontWeight={600}>Oceania:</Text>
      <Text>Standard : Starting from $25</Text>
      <Text mb={3}>Expedited : Starting from $55</Text>

      <Text fontWeight={600}>Africa:</Text>
      <Text mb={3}>Standard : Starting from $15</Text>
    </>
  );
};

const ShippingPolicyPage = () => {
  const { storeLocation } = useStoreLocation();

  return (
    <Flex py={4} px={3} width="100%" maxWidth="600px" flexDirection="column" margin="0 auto">
      <Heading textAlign="center" mb={3}>
        Shipping Policy
      </Heading>

      <Text fontWeight="600" mb={3}>
        COVID-19
      </Text>

      <Text mb={3}>
        As a result of the ongoing COVID-19 pandemic, we are doing everything we can to prevent any disruption to our
        services. International untracked and tracked post may be subject to delays or restrictions as a result of
        changes in transport procedures (e.g. border controls and cancellation of previously used flight connections),
        changes in delivery procedures in the destination country (e.g. quarantine and / or other protective measures)
        and more.
      </Text>

      {/* <Text fontWeight="600" mb={3}>
        EU Customers
      </Text>

      <Text mb={3}>
        If you are looking to purchase our products and live in the EU, please note that
        since January 1st 2021 you may incur additional customs duties and local VAT once
        the product has arrived in your country of residence. At this stage we cannot
        provide you with an estimate of these charges as it varies from country to country
        and will be based on the weight, price and dimensions of your order. We will
        continually monitor the situation and hope to provide you with better estimates
        down the line. We feel it is important you are aware of this before purchasing
        when outside of the UK.
      </Text> */}

      <Text fontWeight="600" mb={3}>
        Delivery Options
      </Text>

      <Text mb={3}>Final shipping fees are calculated at checkout based on weight and location.</Text>

      {storeLocation === "uk" ? <UKShipping /> : <USShipping />}

      <Text>You will receive an email once your order has been dispatched.</Text>
      <Text>
        The above mentioned delivery dates are estimates and should be used as a guide. All LEAN orders are shipped
        using external postal services and / or couriers. Due to the current circumstances, deliveries can take longer
        than normal.
      </Text>

      <Text mb={3}>
        The above mentioned delivery dates are estimates and should be used as a guide. All LEAN orders are shipped
        using external postal services and / or couriers. Due to the current circumstances, deliveries can take longer
        than normal.
      </Text>

      <Text mb={3}>
        Should you wish to have further information regarding shipping, please visit the relevant website below:
      </Text>

      <Box mb={3}>
        <ul>
          <li>
            Royal Mail:{" "}
            <ExternalLink
              href="https://www.royalmail.com/coronavirus"
              target="_blank"
              rel="noopener noreferrer"
              aria-describedby="a11y-new-window-external-message"
            >
              https://www.royalmail.com/coronavirus
            </ExternalLink>{" "}
          </li>
          <li>
            DHL:{" "}
            <ExternalLink
              href="https://parcel.dhl.co.uk/covid-19/"
              target="_blank"
              rel="noopener noreferrer"
              aria-describedby="a11y-new-window-external-message"
            >
              COVID-19
            </ExternalLink>{" "}
          </li>
          <li>
            DPD:{" "}
            <ExternalLink
              href="https://www.dpd.co.uk/content/products_services/covid-19-bulletins.jsp"
              target="_blank"
              rel="noopener noreferrer"
              aria-describedby="a11y-new-window-external-message"
            >
              https://www.dpd.co.uk/content/products_services/covid-19-bulletins.jsp
            </ExternalLink>{" "}
          </li>
          <li>
            Aramex:{" "}
            <ExternalLink
              href="https://www.aramex.com/us/en/updates-related-to-covid-19"
              target="_blank"
              rel="noopener noreferrer"
              aria-describedby="a11y-new-window-external-message"
            >
              Updates Related to COVID-19
            </ExternalLink>{" "}
          </li>
          <li>
            Emirates Post:{" "}
            <ExternalLink
              href="https://www.emirates.com/ae/english/help/covid-19/"
              target="_blank"
              rel="noopener noreferrer"
              aria-describedby="a11y-new-window-external-message"
            >
              https://www.emirates.com/ae/english/help/covid-19/
            </ExternalLink>{" "}
          </li>
        </ul>
      </Box>

      <Text mb={3}>
        Due to the ongoing COVID-19 pandemic, we are currently unable to deliver orders to the following countries:
        Bolivia, Cuba, Haiti, Iran, Liberia, Myanmar (Burma), Nicaragua, Palestinian Territories, Paraguay, Sierra
        Leone, South Sudan, Turkmenistan and Venezuela.
      </Text>

      <Text mb={3} fontWeight="600">
        Shipping Options
      </Text>

      <Text mb={3}>
        Tracked: Track your order. Receive a tracking number and tracking link so that you will be able to follow the
        status of your order on the courier’s website.
      </Text>

      <Text mb={3}>
        Untracked: Standard delivery without the ability to track your order. You will receive one email when your order
        is dispatched.
      </Text>

      <Text mb={3} fontWeight="600">
        Returned to Seller Orders
      </Text>

      <Text mb={3}>
        Some orders may be stopped and returned to seller by the postal provider / courier service. In this case,
        customers are offered 2 options:
      </Text>

      <Box mb={3}>
        <ol>
          <li>A full refund: issued upon receipt of the parcel at our distribution centre</li>
          <li>
            Items re-dispatched: upon receipt of the parcel at our distribution centre, your purchase will be
            re-dispatched. Customers will be asked before dispatch to re – confirm their shipping address and a possible
            addition of a phone number to ensure the postal service / courier service can contact the customer directly
            in the case of any further queries relating to delivery
          </li>
        </ol>
      </Box>

      <Text mb={3}>
        The length of time it takes for a parcel to arrive can vary. Our distribution centre is located within the UK.
        For UK orders, delivery can take up to 5 working days. International orders can take between 10 – 20 working
        days. Please note that this is a guide only and deliveries can take less or more time, particularly at this
        current time and due to the pandemic.
      </Text>

      <Text mb={3} fontWeight="600">
        Returning Orders after delivery
      </Text>

      <Text mb={3}>
        Please review our refund policy located{" "}
        <Link to="/policies/refund-policy">
          <Span fontWeight="600" color="grey">
            here.
          </Span>
        </Link>{" "}
      </Text>

      <Text mb={3}>
        Should you wish to return an item and claim a refund, please refer to the refund policy. Downloadable software
        products are non-refundable. Items returned must meet the returns policy and shipping is paid by the customer.
      </Text>

      <Text>
        For sanitary reasons, items must be returned in their original packaging. Failure to do so will result in a
        refund not being issued. Please contact us if you believe you have received incorrect and defective items.
      </Text>
    </Flex>
  );
};

export default ShippingPolicyPage;
